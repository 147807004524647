<template>
  <div class="sidebar">
    <div class="top-logo-company">
      <div class="menu-btn">
        <i class="fad fa-angle-left"></i>
      </div>
      <div class="logo-wrapper">
        <router-link to="/">
          <img :src="imageUrl" alt="" />
          <span class="version"> v 2.0</span>
        </router-link>
      </div>
      <div class="company-user-wrapper">
        <b-avatar :src="userProfile(users.profile)" />
        <div class="user-text">
          <span class="user-company d-block"> {{ company.firma_adi }}</span>
          <span class="user-title d-block">{{ users.name }}</span>
          <span class="user-email d-block">{{ users.email }}</span>
        </div>
      </div>
    </div>
    <div class="sidebar-menu">
      <nav class="nav-menu">
        <ul class="menu">
          <li
            class="menu-item"
            v-for="(item, index) in navMenuItems"
            :key="index"
            :class="item.children.length > 0 ? 'sub' : 'ana'"
            v-if="userRole(item)"
          >
            <router-link
              :to="item.children.length === 0 ? { name: item.route } : '#'"
              :data-toogle="item.children.length > 0 && 'sub-menu'"
              :class="{ active: $route.name == item.route }"
            >
              <i v-if="item.icon" :class="item.icon" />
              <span>{{ item.title }}</span>
            </router-link>
            <ul class="sub-menu border-top-color" v-if="item.children.length">
              <li class="sub-menu-item" v-for="(sub, index) in item.children" :key="index" v-if="userRole(sub)">
                <router-link :to="{ name: sub.route }" :class="{ active: $route.name == sub.route }">
                  <i v-if="sub.icon" :class="sub.icon" />
                  {{ sub.title }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import navMenuItems from '@/navigation';
import { defineComponent, computed, onMounted } from '@vue/composition-api';
import { userRoles } from '@/libs/user_role';
import store from '@/store';
export default defineComponent({
  setup() {
    const expo = {};
    const toggleSideMenuMinimize = computed(() => store.getters.getToggleSideMenuMinimize);
    expo.imageUrl = require('@/assets/images/logo/logo.png');

    expo.userRole = computed(() => {
      return (val) => userRoles(val);
    });

    expo.users = computed(() => store.getters['getUserData']);
    expo.company = computed(() => store.getters['getUserFirma']);

    expo.userProfile = computed((val) => {
      const base_url =
        process.env.NODE_ENV === 'development'
          ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
          : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);
      return (val) => {
        return `${base_url}uploads/${firmaKNo}/no/${val}`;
      };
      // const bs = 'api/public/uploads/no/default.png';

      // return profil;
    });

    expo.userProfile = (val) => {
      if (val != undefined) {
        const fileName = store.getters.getUserFirma.db;
        let url = '';
        const base_url =
          process.env.NODE_ENV === 'development'
            ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
            : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);

        if (val == 'default.png') {
          url = `${base_url}uploads/no/${val}`;
        } else {
          url = `${base_url}uploads/${fileName}/profile/${val}`;
        }
        return url;
      }
    };

    onMounted(() => {
      let width = 0;
      const sidebar = document.querySelector('.sidebar');
      const menuOverlay = document.querySelector('.menu-overlay');
      const toggleMenu = document.querySelector('.toggle-side-menu');
      const menuBtn = document.querySelector('.menu-btn');
      const navMenu = document.querySelector('.nav-menu');
      const bodySelect = document.querySelector('body');

      bodySelect.classList.add(toggleSideMenuMinimize.value);

      if (window.innerWidth <= 991) {
        bodySelect.classList.add('sidebar-mobile');
      }

      window.addEventListener('resize', () => {
        width = window.innerWidth;
        if (width > 991) {
          // toogleMenuNav();
          bodySelect.classList.remove('sidebar-mobile');
          if (sidebar.classList.contains('open-menu')) {
            toogleNav();
          }
        } else {
          bodySelect.classList.remove('sidebar-mini');
          bodySelect.classList.add('sidebar-mobile');
        }
      });

      function toogleNav() {
        sidebar.classList.toggle('open-menu');
        menuOverlay.classList.toggle('active');
      }

      function toogleMenuNav() {
        bodySelect.classList.toggle('sidebar-mini');
        if (bodySelect.classList.contains('sidebar-mini')) {
          store.commit('TOGGLE_SIDE_MENU_MINIMIZE', 'sidebar-mini');
        } else {
          store.commit('TOGGLE_SIDE_MENU_MINIMIZE', null);
        }
      }

      function collapseSubMenu() {
        navMenu.querySelector('.menu-item.active .sub-menu').removeAttribute('style');
        navMenu.querySelector('.menu-item.active').classList.remove('active');
      }

      function collapseAnaMenu() {
        navMenu.querySelector('.menu-item.active').classList.remove('active');
      }

      toggleMenu.addEventListener('click', toogleNav);
      menuOverlay.addEventListener('click', toogleNav);

      menuBtn.addEventListener('click', () => {
        toogleMenuNav();
      });

      navMenu.addEventListener('click', (e) => {
        let menuItemHasChildren = null;
        if (e.target.getAttribute('data-toogle') == 'sub-menu' || e.target.tagName == 'A') {
          menuItemHasChildren = e.target.parentElement;
        } else if (e.target.tagName == 'SPAN' || e.target.tagName == 'I') {
          menuItemHasChildren = e.target.parentElement.parentElement;
        }

        if (
          menuItemHasChildren != null &&
          (e.target.getAttribute('data-toogle') == 'sub-menu' || e.target.tagName == 'SPAN' || e.target.tagName == 'I')
        ) {
          if (menuItemHasChildren.classList.contains('active')) {
            collapseSubMenu();
          } else {
            if (navMenu.querySelector('.menu-item.ana.active')) {
              collapseAnaMenu();
            }
            if (navMenu.querySelector('.menu-item.sub.active')) {
              collapseSubMenu();
            }
            menuItemHasChildren.classList.add('active');
            const subMenu = menuItemHasChildren.querySelector('.sub-menu');
            if (subMenu != null || subMenu != undefined) subMenu.style.maxHeight = subMenu.scrollHeight + 'px';
          }
        } else {
          if (!e.target.parentElement.classList.contains('sub-menu-item')) {
            if (navMenu.querySelector('.menu-item.ana.active')) {
              collapseAnaMenu();
            }
            if (navMenu.querySelector('.menu-item.sub.active')) {
              collapseSubMenu();
            }
            menuItemHasChildren.classList.add('active');
          } else {
            e.target.classList.add('active');
          }
        }

        if (bodySelect.classList.contains('sidebar-mini')) {
          if (menuItemHasChildren.classList.contains('sub-menu-item')) {
            collapseSubMenu();
          }
        }
      });
    });

    return { ...expo, navMenuItems };
  },
});
</script>

<style lang="scss" scoped></style>
