import dashboard from './dashboard';
import magaza from './magaza';
import urunler from './urunler';
import acenteler from './acenteler';
import rezervasyonlar from './rezervasyonlar';
import tanimlamalar from './tanimlamalar';
import destek from './destek';
import raporlar from './raporlar';
import kullanicilar from './kullanicilar';
import yonetim from './yonetim';

export default [
  ...dashboard,
  ...magaza,
  ...rezervasyonlar,
  ...urunler,
  ...acenteler,
  ...tanimlamalar,
  ...destek,
  ...raporlar,
  ...kullanicilar,
  ...yonetim,
];
