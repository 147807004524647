export default [
  {
    title: 'Mağaza İşlemleri',
    icon: 'fad fa-store',
    children: [
      {
        title: 'Sayfalar',
        route: 'sayfa-listele',
        icon: 'fad fa-files-medical',
      },
      {
        title: 'Slider',
        route: 'slider',
        icon: 'fad fa-images',
      },
      {
        title: 'Banner',
        route: 'banner',
        icon: 'fad fa-images',
      },
      {
        title: 'Splash Bildirimler',
        route: 'splash-bildirimler',
        icon: 'fad fa-bells',
      },
    ],
  },
];
