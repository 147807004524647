<template>
  <img class="app-logo" :src="require('@/assets/images/logo/logo.png')" alt="" />
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup() {
    const imageUrl = require('@/assets/images/logo/logo.png');

    return { imageUrl };
  },
});
</script>

<style lang="scss" scoped>
.app-logo {
  width: auto;
  height: 35px;
  opacity: 0;
  visibility: hidden;
}
</style>
