export default [
  {
    title: 'Rezervasyon İşlemleri',
    icon: 'fad fa-receipt',
    children: [
      {
        title: 'Rezervasyonlar',
        route: 'rezervasyon-listele',
        icon: 'fad fa-receipt',
      },
      {
        title: 'Ön Talepler',
        route: 'on-talep-listele',
        icon: 'fad fa-street-view',
      },
      {
        title: 'Özel Talepler',
        route: 'ozel-talep-listele',
        icon: 'fad fa-users-crown',
      },
      {
        title: 'İptal Talepler',
        route: 'iptal-talep-listele',
        icon: 'fad fa-users-slash',
      },
      {
        title: 'Ödeme Bildirim',
        route: 'odeme-bildirimler',
        icon: 'fad fa-cash-register',
      },
    ],
  },
];
