<template>
  <div class="top-nav-bar" ref="el">
    <div class="top-bar-overlay" />
    <ul class="top-bar-menu">
      <li class="top-bar-menu-item full-screen pr-4">
        <a href="#" v-b-tooltip.hover.bottom="'Tam Ekran'" @click="toggleFullScreen">
          <i :class="!fullscreenElement ? 'fad fa-expand-wide fa-2x' : 'fad fa-compress-wide fa-2x'"></i>
        </a>
      </li>
      <li class="top-bar-menu-item pr-4">
        <a href="#" v-b-tooltip.hover.bottom="'Kurlar'">
          <i class="fad fa-globe-asia fa-2x" data-toogle="sub-menu"></i>
          <i class="fa fa-caret-down" data-toogle="sub-menu"></i>
        </a>
        <ul class="top-bar-sub-menu">
          <li
            class="top-bar-sub-menu-item"
            :class="{ active: defaultDil == item.lang }"
            v-for="(item, index) in diller"
            :key="index"
            @click="handleChangeLanguage(item)"
          >
            <span>{{ item.title }}</span>
          </li>
        </ul>
      </li>
      <li class="top-bar-menu-item pr-4">
        <a href="#" v-b-tooltip.hover.bottom="'Kurlar'">
          <i class="fad fa-exchange-alt fa-2x" data-toogle="sub-menu"></i>
          <i class="fa fa-caret-down" data-toogle="sub-menu"></i>
        </a>
        <ul class="top-bar-sub-menu">
          <li
            class="top-bar-sub-menu-item"
            :class="{ active: exhange.banka_kodu == item.banka_kodu }"
            v-for="(item, index) in kurlar"
            :key="index"
            @click="handleChangeCurrency(item)"
          >
            <span>{{ item.banka_kodu }}</span> {{ item.satis | cur }} TRY
          </li>
        </ul>
      </li>
      <li class="top-bar-menu-item text-success">
        <router-link to="/rezervasyon/urunler" class="pr-4" v-b-tooltip.hover.bottom="'Rezervasyon Ekle'">
          <i class="fad fa-bell-plus fa-2x"></i>
        </router-link>
      </li>
      <li class="top-bar-menu-item">
        <router-link to="/rezervasyon/sepet" class="pr-4" v-b-tooltip.hover.bottom="'Sepetim'">
          <b-badge pill variant="success" class="top-menu-badge">{{ sepet.sepet_urunler.length }}</b-badge>
          <i class="fad fa-shopping-basket fa-2x"></i>
        </router-link>
      </li>
      <li class="top-bar-menu-item open-sub-item">
        <a href="#" v-b-tooltip.hover.bottom="'Kullanıcı Menü'">
          <i class="fad fa-user fa-2x" data-toogle="sub-menu"></i>
          <i class="fa fa-caret-down" data-toogle="sub-menu"></i>
        </a>
        <ul class="top-bar-sub-menu">
          <li class="top-bar-sub-menu-item" v-for="(item, index) in dropDownMenu" :key="index" v-if="userRole(item) || item.visible">
            <DropDownItem :item="item" />
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, defineComponent, computed, onMounted, watch } from '@vue/composition-api';
import { useRouter } from '@/libs/utils';
import { userRoles } from '@/libs/user_role';
import DropDownItem from './DropDownItem.vue';
import store from '@/store';
export default defineComponent({
  components: { DropDownItem },
  setup() {
    const expo = {};
    const { route, router } = useRouter();
    expo.toggleMenu = ref(false);
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = computed(() => store.getters.getDilAyarlari.varsayilan);
    expo.kurlar = computed(() => store.getters.getKurListesi);
    expo.exhange = computed(() => store.getters.getExchange);

    expo.fullscreenElement = ref(false);

    expo.toggleFullScreen = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
        expo.fullscreenElement.value = true;
      } else {
        document.exitFullscreen();
        expo.fullscreenElement.value = false;
      }
    };

    expo.dilNe = computed(() => {
      return (val) => {
        return expo.diller.value.find((x) => x.lang == val);
      };
    });
    expo.dropDownMenu = ref([
      {
        title: 'Ayarlar',
        icon: 'cog',
        route: 'ayarlar',
      },
      {
        title: 'Tema Ayarlari',
        icon: 'theater-masks',
        route: 'tema-ayar',
      },
      {
        title: 'Profilim',
        icon: 'user',
        route: 'profilim',
      },
      {
        title: 'Çıkış Yap',
        icon: 'lock',
        route: '',
        visible: true,
      },
    ]);

    expo.sepet = computed(() => store.getters.getDirekRezervasyon);

    expo.handleChangeCurrency = (event) => {
      store.commit('setExchange', event);

      expo.sepet.value.secilen_kur = event.banka_kodu;
      expo.sepet.value.secilen_kur_fiyat = event.satis;
    };

    expo.handleChangeLanguage = (item) => {
      store.commit('SET_DEAFULT_DIL_AYARLARI', item.lang);
      router.go(0);
    };

    onMounted(() => {
      const topBarNav = document.querySelector('.top-nav-bar');
      const topOverlay = document.querySelector('.top-bar-overlay');
      const topBarMenu = document.querySelector('.top-bar-menu');

      topOverlay.addEventListener('click', () => {
        topOverlay.classList.remove('active');
        document.querySelector('.top-bar-menu-item.active').classList.remove('active');
        document.querySelector('.top-bar-sub-menu.open').classList.remove('open');
      });

      topBarNav.addEventListener('click', (e) => {
        const menuItemHasChildren = e.target.parentElement.parentElement;
        if (e.target.getAttribute('data-toogle') == 'sub-menu') {
          if (menuItemHasChildren.classList.contains('active')) {
            topOverlay.classList.remove('active');
            menuItemHasChildren.classList.remove('active');
            menuItemHasChildren.lastElementChild.classList.remove('open');
          } else {
            topOverlay.classList.add('active');
            menuItemHasChildren.classList.add('active');
            menuItemHasChildren.lastElementChild.classList.add('open');
          }
        }

        if (e.target.classList.contains('top-bar-sub-menu-item')) {
          topOverlay.classList.remove('active');
          menuItemHasChildren.classList.remove('active');
          menuItemHasChildren.lastElementChild.classList.remove('open');
        }
      });
    });

    expo.userRole = computed(() => {
      return (val) => userRoles(val);
    });

    watch(
      expo.sepet,
      () => {
        // sepetToplamHesapla();
      },
      { deep: true, immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.top-bar-overlay {
  position: absolute !important;
  // z-index: 2;
  // background-color: rgba(0, 0, 0, 0.5);
  left: 0px;
  top: 0px;
  min-height: 100vh;
  height: 100vh;
  width: 100%;
  min-width: 100%;
  visibility: hidden;
  &.active {
    visibility: visible;
  }
}
.top-bar-menu {
  padding-right: 20px;
  margin: 2px 0px;
  display: flex;
  .top-bar-menu-item {
    position: relative;
    display: inline-block;
    margin: 0;
    > a {
      display: flex;
      padding: 8px 0px;
      font-size: 14px;
      text-transform: capitalize;
      font-weight: 400;
      transition: all 0.2s ease-in-out;
      color: inherit;
      align-items: center;
      .top-menu-badge {
        position: absolute;
        z-index: 1;
        right: 14px;
        top: 3px;
      }
      i {
        padding-left: 2px;
        padding-right: 2px;
        transition: all 0.2s ease-in-out;
      }
      span {
        text-transform: uppercase;
      }
      @media only screen and (max-width: 992px) {
        span {
          display: none;
        }
      }
    }

    &.full-screen {
      @media only screen and (max-width: 992px) {
        display: none;
      }
    }

    .top-bar-sub-menu {
      position: absolute;
      min-width: 200px;
      left: auto;
      right: 0;
      z-index: 10;
      background: #fff;
      border-radius: 0 0 4px 4px;
      border: 1px solid #eee;
      border-top: 3px solid #777;
      transition: all 0.2s ease-in-out;
      transform: translateY(10px);
      list-style: none;
      text-align: left;
      opacity: 0;
      visibility: hidden;
      color: rgb(72, 72, 72);
      padding: 0 0;
      top: 47px;

      &.open {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }
      .top-bar-sub-menu-item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #eee;
        padding: 10px 10px;
        cursor: pointer;

        &:last-child {
          border-bottom: none;
        }
        a {
          margin-left: 5px;
          display: block;
          transition: all 0.2s ease-in-out;
          text-transform: uppercase;
          color: inherit;
          i {
            font-size: 20px;
            padding-right: 10px;
          }
          &:hover {
            transform: translateX(10px);
          }
        }
        &.active {
          background: #b0b0b069;
        }
      }
    }
  }
}
</style>
