<template>
  <div class="toggle-side-menu">
    <i class="fad fa-bars fa-2x"></i>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup() {},
});
</script>

<style lang="scss" scoped>
.toggle-side-menu {
  padding-left: 30px;
  display: block;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  @media only screen and (max-width: 992px) {
    opacity: 1;
    visibility: visible;
  }
}
</style>
