<template>
  <div class="header-bottom">
    <div class="tag-view-list">
      <router-link
        to="#"
        class="tags-item"
        :class="{ active: isActive(item) }"
        v-for="(item, index) in tagViewsList"
        :key="index"
        :to="{ path: item.path, query: item.query, fullPath: item.fullPath }"
      >
        <span class="tag-view-title">
          {{ item.meta.title }}
        </span>
        <span class="tag-view-close">
          <i class="fad fa-times-circle" v-if="!isAffix(item)" @click.prevent.stop="closeSelectedTag(item)" />
        </span>
      </router-link>
    </div>

    <div class="tag-view-all-close" v-if="tagViewsList.length > 1">
      <i class="fad fa-times-circle" @click.prevent.stop="closeAllTags" />
    </div>
  </div>
</template>

<script>
import { ref, computed, defineComponent, watch } from '@vue/composition-api';
import { useRouter } from '@/libs/utils';
import store from '@/store';
export default defineComponent({
  setup() {
    const expo = {};

    const { route, router } = useRouter();

    expo.tagViewsList = computed(() => store.getters.getTagViews);

    expo.isActive = (ro) => {
      return ro.path === route.value.path;
    };

    expo.isAffix = (tag) => {
      return tag.meta && tag.meta.affix;
    };

    const addTags = () => {
      const { name, hash, meta } = route.value;
      if (name && hash != '#' && meta.noCache) {
        store.dispatch('addView', route.value);
      }
      return false;
    };

    expo.closeSelectedTag = (view) => {
      store.dispatch('deleteView', view).then(({ tag_views }) => {
        if (expo.isActive(view)) {
          expo.toLastView(tag_views, view);
        }
      });
    };

    expo.toLastView = (visitedViews, view) => {
      const latestView = visitedViews.slice(-1)[0];
      if (latestView) {
        router.push(latestView.fullPath);
      } else {
        if (view.meta.title === 'Dashboard') {
          router.replace({ path: '/redirect' + view.fullPath });
        } else {
          router.push('/');
        }
      }
    };

    expo.closeAllTags = (view) => {
      store.dispatch('delAllViews').then(({ tag_views }) => {
        expo.toLastView(tag_views, view);
      });
    };

    watch(route, (val) => {
      addTags();
    });

    return { ...expo };
  },
});
</script>

<style scoped></style>
