export default [
  {
    title: 'Ürün İşlemleri',
    icon: 'fad fa-box-full',
    children: [
      {
        title: 'Ürünler',
        route: 'urun-listele',
        icon: 'fad fa-list-alt',
      },
      {
        title: 'Transfer Tarifeleri',
        route: 'transfer-urun-listele',
        icon: 'fad fa-plane-alt',
      },
      {
        title: 'Ürün Takvimi',
        route: 'urun-takvim-listele',
        icon: 'fad fa-calendar-day',
      },
    ],
  },
];
