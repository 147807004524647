<template>
  <div class="app-content">
    <transition :name="transitionEffect" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
import store from '@/store';
export default defineComponent({
  setup() {
    const expo = {};
    expo.transitionEffect = computed(() => store.getters.getTransitionEffect);
    expo.toggleSideMenu = computed(() => store.getters.getToggleSideMenu);

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
