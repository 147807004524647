export default [
  {
    title: 'Kullanıcılar',
    route: 'kullanicilar',
    icon: 'fad fa-users',
    children: [
      {
        title: 'Kullanıcı Listele',
        route: 'kullanici-listele',
        icon: 'fad fa-user',
      },
      {
        title: 'Kullanıcı Yetkiler',
        route: 'kullanici-yetkiler',
        icon: 'fad fa-eye-slash',
      },
    ],
  },
];
