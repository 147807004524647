<template>
  <div class="h-100">
    <VerticalOverlay />
    <VerticalMenu />
    <VerticalHeader />
    <VerticalContent />
  </div>
</template>

<script>
import VerticalOverlay from './VerticalOverlay.vue';
import VerticalMenu from './VerticalMenu.vue';
import VerticalContent from './VerticalContent.vue';
import VerticalHeader from './VerticalHeader.vue';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    VerticalOverlay,
    VerticalHeader,
    VerticalMenu,
    VerticalContent,
  },
  setup() {},
});
</script>

<style lang="scss" scoped></style>
