<template>
  <header class="navbar header-navbar">
    <div class="header-top">
      <ToggleMenu />
      <Logo />
      <UserDropDown />
    </div>
    <TagsView />
  </header>
</template>

<script>
import Logo from '../component/AppHeader/Logo.vue';
import ToggleMenu from '../component/AppHeader/ToggleMenu.vue';
import UserDropDown from '../component/AppHeader/UserDropDown.vue';
import TagsView from '../component/AppHeader/TagsView.vue';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  components: {
    Logo,
    ToggleMenu,
    UserDropDown,
    TagsView,
  },
  setup() {
    const expo = {};
    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
