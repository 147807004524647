<template>
  <router-link v-if="item.route" :to="{ name: item.route }" class="d-flex justify-content-start align-items-center">
    <i :class="`fad fa-${item.icon}`" />
    <span class="pl-1">{{ item.title }}</span>
  </router-link>
  <a href="#" v-else @click="handlerLogout" class="d-flex justify-content-start align-items-center">
    <i :class="`fad fa-${item.icon}`" />
    <span class="pl-1">{{ item.title }}</span>
  </a>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import store from '@/store';
export default defineComponent({
  props: {
    item: [Object],
  },
  setup() {
    const expo = {};
    expo.handlerLogout = () => {
      store.dispatch('logout');
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
