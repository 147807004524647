export default [
  {
    title: 'Tanımlamalar',
    icon: 'fad fa-clipboard-list',
    children: [
      {
        title: 'Kategoriler',
        route: 'kategoriler',
        icon: 'fad fa-list-alt',
      },
      {
        title: 'Bölgeler',
        route: 'bolgeler',
        icon: 'fad fa-location',
      },
      {
        title: 'Hoteller',
        route: 'hotel-listele',
        icon: 'fad fa-hotel',
      },
      {
        title: 'Transfer İşlemleri',
        route: 'tarife-bolgeleri',
        icon: 'fad fa-location',
      },

      {
        title: 'Ekstra Hizmetler',
        route: 'ekstra-hizmetler',
        icon: 'fad fa-concierge-bell',
      },
      {
        title: 'Ürün Özel Alanlar',
        route: 'urun-ozel-alan',
        icon: 'fad fa-sparkles',
      },
      {
        title: 'Varyant Grupları',
        route: 'varyant-gruplari',
        icon: 'fad fa-poll-people',
      },
      {
        title: 'Rezervasyon Durumları',
        route: 'rezervasyon-durumlari',
        icon: 'fad fa-shopping-basket',
      },
      {
        title: 'Üye Grupları',
        route: 'uye-gruplari',
        icon: 'fad fa-users-medical',
      },
      {
        title: 'Kampanyalar',
        route: 'kampanyalar',
        icon: 'fad fa-bullhorn',
      },
      {
        title: 'Kuponlar',
        route: 'kuponlar',
        icon: 'fad fa-ticket',
      },
    ],
  },
];
